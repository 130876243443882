import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import bild1 from '../assets/Bild1.png'
import bild2 from '../assets/Bild2.png'
import bild3 from '../assets/Bild3.png'
import bild4 from '../assets/Bild4.png'

export default function HittaHit(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-start align-items-center gap-2 embedded-iframe pt-4">
        <Button variant="success" onClick={() =>navigate("/info")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="pt-2" style={{fontSize:"16pt", maxWidth:"90%"}}><strong>Hitta till Senapsvägen 22</strong></p>
        <p style={{fontSize:"16pt", maxWidth:"90%"}}>
            <ul><li><strong>Buss 🚌</strong> - Buss 314 från Halmstad centrum till Gullbranna (Ev via Eldsberga). Går fram till klockan 01:30 på kvällen. Hoppa av vid hållplats Dillvägen (c:a 250 meter till Senap) Resa kan enklast sökas och betalas genom mobilappen Hallandstrafiken.
        </li>
        <br/>
        <li><strong>Bil 🚗</strong> - Adressen är Senapsvägen 22, 30596 Eldsberga. Se bilderna nedan för kör- & parkeringsanvisningar</li>
        </ul></p>
        <img style={{maxWidth:"100%"}}src={bild1}></img>
        <img style={{maxWidth:"100%"}}src={bild2}></img>
        <img style={{maxWidth:"100%"}}src={bild3}></img>
        <img style={{maxWidth:"100%"}}src={bild4}></img>
        </div>
    )
    

}