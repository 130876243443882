import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



export default function Unsure() {
    const navigate = useNavigate();
    return (
    <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
    <Button variant="success" onClick={() =>navigate("/")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
    <p className="text-center" style={{fontSize:"18pt", maxWidth:"80%"}}>Men så tråkigt att du/ni inte har möjlighet att komma 😢 - men jag återkommer när jag fyller 70 och hoppas på bättre tur då! Fyll gärna i namn nedan, så jag inte besvärar er med påminnelser inför den 17 juni.</p>
    <iframe className="bg-white"src="https://docs.google.com/forms/d/e/1FAIpQLSeAH71ednrBoEijUUGSpzVrxobdqRi-gypE8ZInMNgCwFxYhA/viewform?embedded=true" width="640" height="941">Läser in …</iframe>
    </div>
    )
}