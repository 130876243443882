import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function Anmalan(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
        <p className="text-center">Önskar dig/er en riktigt trevlig kväll den 17 juni på annat håll! </p>
        <Button variant="success" onClick={() => window.location.href = "https://www.google.se"}>Tack!</Button>
        </div>
    )
    

}