import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function Anmalan(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
        <Button variant="success" onClick={() =>navigate("/")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="text-center" style={{fontSize:"18pt", maxWidth:"80%"}}>Men så kul att du/ni kan komma!!! Du/ni ska verkligen känna er varmt välkomna! Toppen om du/ni fyller i namn & epost nedan så jag har möjlighet att uppdatera med lite matnyttigt & förhoppnings trevligt inför mitt lilla kalas! </p>
        <iframe className="bg-white"src="https://docs.google.com/forms/d/e/1FAIpQLSeMrinxKGzqZPfJRuef33JQG7T9hgDmLhDM-AwDON_a9EGdWg/viewform?embedded=true" width="640" height="941">Läser in …</iframe>
        </div>
    )
    

}