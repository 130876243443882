import './App.css';
import {Routes, Route, BrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import Anmalan from './pages/Anmalan';
import Who from './pages/Who';
import Unsure from './pages/Unsure';
import Unable from './pages/Unable';
import MerInfo from './pages/MerInfo';
import MatPref from './pages/Matpreferenser';
import Onskelat from './pages/Onskelat';
import Minnesbilder from './pages/Minnesbilder';
import HittaHit from './pages/HittaHit';
import Boende from './pages/Boende';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/anmalan" element={<Anmalan/>}/>
        <Route path="/anmalan/unknown" element={<Who/>}/>
        <Route path="/anmalan/unsure" element={<Unsure/>}/>
        <Route path="/anmalan/unable" element={<Unable/>}/>
        <Route path="/info" element={<MerInfo/>}/>
        <Route path="/info/matpref" element={<MatPref/>}/>
        <Route path="/info/spellista" element={<Onskelat/>}/>
        <Route path="/info/minnesbilder" element={<Minnesbilder/>}/>
        <Route path="/info/boende" element={<Boende/>}/>
        <Route path="/info/hitta" element={<HittaHit/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App;