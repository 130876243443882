

import kalas from '../assets/kalas.png'

import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import { useNavigate } from 'react-router-dom';
import { isMinusToken } from 'typescript';

export default function Home() {

  const navigate = useNavigate();


  return (
    <div className="App d-flex flex-column align-items-center">
      <div id="overlay"><h1 style={{fontSize:"5vw"}}><strong>TOMMY HAR FYLLT 60ÅR!</strong></h1></div>
      <div id="wrapper" className='d-flex justify-content-center' style={{minWidth:"100%", position:"relative"}}>
      
        <div style={{background:"white", width:"50%"}} className="d-flex">
          <div id="balloon-container">
          <div className='balloon text-center'></div>
          <div className='balloon text-center mt-5'></div>
          <div className='balloon text-center'></div>
          <div className='balloon text-center mt-5'></div>
          <div className='balloon text-center'></div>
          </div>
        </div>
        <div style={{background:"red", width:"50%", position:"relative"}}>
        </div>
        <img id="kalas" src={kalas}></img>
      </div>
      
      <h1>Varmt välkomna kl 19 den 17 juni!</h1>
      <Button onClick={() => navigate("/info")} className='text-white m-4' variant="success" style={{fontWeight:"500", fontSize:"24pt"}}>Mer info & önskemål inför samkvämet</Button>
      <div className='d-flex align-items-center' style={{flexWrap:"wrap"}}>
        <div className='m-4 bg-white rounded gradient-border' style={{maxWidth:"40em", minWidth:"20em"}}>
         
        </div>
        
     </div>
    </div>
      
    
  );
}





