import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function MatPref(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
        <Button variant="success" onClick={() =>navigate("/info")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="text-center" style={{fontSize:"18pt", maxWidth:"80%"}}>Meddela gärna om det är något i mat eller dryckesväg du inte tål - eller tycker väldigt illa om…..</p>
        <iframe className="bg-white" src="https://docs.google.com/forms/d/e/1FAIpQLSe94vhcuC68dawGdSWkNbq5uvrwIUr7XXLPf1BKToxmilH0-Q/viewform?embedded=true" width="640" height="630">Läser in …</iframe>
        </div>
    )
    

}