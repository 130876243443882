import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



export default function Unsure() {
    const navigate = useNavigate();
    return (
    <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
    <Button variant="success" onClick={() =>navigate("/")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
    <p className="text-center" style={{fontSize:"18pt", maxWidth:"80%"}}>Det är ett tag till den 17 juni, håller t & t för att du/ni får möjligheten att komma - ni ska verkligen känna er varmt välkomna!! Toppen om du/ni fyller i namn & epost nedan så jag har möjlighet att påminna dig/er 😊 - men också uppdatera med lite matnyttigt & förhoppnings trevligt inför mitt lilla kalas!</p>
    <iframe className="bg-white"src="https://docs.google.com/forms/d/e/1FAIpQLSdqOEvdS7VBetUBnu4rZ5c9GFkoT81BDbcCn3UWYklqUj2UDg/viewform?embedded=true" width="640" height="941">Läser in …</iframe>
    </div>
    )
}