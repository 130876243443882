import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function Minnesbilder(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-center align-items-center gap-2 pt-2">
        <Button variant="success" onClick={() =>navigate("/info")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="text-center p-4" style={{fontSize:"18pt"}}>Väldans trevligt om du har några minnesbilder från gemensamma upplevelser du vill dela med dig av!!! <br/><br/>Skicka till <strong>minnesbilder@tommyfyller60.se</strong></p>
        </div>
    )
    

}