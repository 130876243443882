import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



export default function MerInfo() {
    const navigate = useNavigate();
    return (
    <div className="App d-flex flex-column justify-content-start align-items-center gap-2 pt-4">
    <Button className="m-5" variant="success" onClick={() =>navigate("/")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
    <div className="d-flex flex-column gap-4">
    <Button variant="success" onClick={() =>navigate("/info/hitta")} style={{maxWidth:"20em"}}>Hitta till samkvämet</Button>
    <Button variant="success" onClick={() =>navigate("/info/boende")} style={{maxWidth:"20em"}}>Tips på boende</Button>
    <Button variant="success" onClick={() =>navigate("/info/matpref")} style={{maxWidth:"20em"}}>Anmäl matpreferenser</Button>
    <Button variant="success" onClick={() =>navigate("/info/spellista")} style={{maxWidth:"20em"}}>Önskelåten</Button>
    <Button variant="success" onClick={() =>navigate("/info/minnesbilder")} style={{maxWidth:"20em"}}>Minnesbilder</Button>
    </div>
    </div>
    )
}