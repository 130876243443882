import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function Onskelat(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-center align-items-center gap-2 embedded-iframe pt-2">
        <Button variant="success" onClick={() =>navigate("/info")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="text-center" style={{fontSize:"18pt", maxWidth:"80%"}}>Kul om du vill vara med och bidra till samkvämets låtlista. Skicka in dina partypinglor!</p>
        <iframe className="bg-white" src="https://docs.google.com/forms/d/e/1FAIpQLSfhPMH5DMTaNzmtCwnkO5qgT3V4_eWrtM91EIuqGKscJLqZ9Q/viewform?embedded=true" width="640" height="600
    ">Läser in …</iframe>
        </div>
    )
    

}