import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

export default function Boende(){
   
    const navigate = useNavigate();
    
    return (
        <div className="App d-flex flex-column justify-content-start align-items-center gap-2 embedded-iframe pt-4">
        <Button variant="success" onClick={() =>navigate("/info")} style={{maxWidth:"10em"}}>Gå tillbaka</Button>
        <p className="" style={{fontSize:"16pt", maxWidth:"90%"}}>
            <ul>
                <li><strong>Goodmorning hotel Hallarna</strong> - Enkelt och prisvärt hotell vid Hallarna shoppingcenter nära busslinje 314 till Gullbranna. <br/>
                <a href="https://ligula.se/goodmorninghotels/halmstad/">Länk till deras hemsida</a></li><br/>
                <li><strong>Gullbrannagården Camping och stugor</strong> - I norra ändan av Gullbranna. Gångavstånd till Senap 22. (Tyvärr ganska fullbokat under sommarmånaderna)<br/>
                <a href="https://www.gullbrannagarden.se">Länk till deras hemsida</a></li> 
            </ul>
        </p>
        
        </div>
    )
    

}